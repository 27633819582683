import React from 'react';

import { Image } from '@wix/ambassador-social-groups-v2-group/types';

import { Media } from 'wui/Media';

import { EImageRatio } from 'settings/constants';

interface GroupImageProps
  extends Omit<React.ComponentProps<typeof Media>, 'src' | 'aspectRatio'> {
  image?: Image;
  aspectRatio?: EImageRatio;
}

export function GroupImage({ image, aspectRatio, ...rest }: GroupImageProps) {
  return (
    <Media
      isInFirstFold
      src={(image?.mediaId as string) || (image?.fileUrl as string)}
      sourceHeight={image?.height as number}
      sourceWidth={image?.width as number}
      aspectRatio={toAspectRatio(aspectRatio)}
      {...rest}
    />
  );

  function toAspectRatio(ratio?: EImageRatio) {
    if (ratio === undefined) {
      return;
    }

    return ratio === EImageRatio.Rectangle ? 'cinema' : 'square';
  }
}

GroupImage.displayName = 'GroupImage';
